import { EMPTY_EDI_MONITORING_MODEL } from 'presentation/model/EdiMonitoring/EdiMonitoringModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: EdiMonitoringProvider,
    useTracked: useEdiMonitoringTracked
} = createContainer(() => useState(EMPTY_EDI_MONITORING_MODEL), {concurrentMode: true});
export { EdiMonitoringProvider, useEdiMonitoringTracked };

