
export const EdiMonitoringConstant = { 
    MONITOR: 'Invoice/Credit Note Monitoring',
    MONITOR_LIST: 'Document Monitoring List',
    CHARGE_TYPE: 'Charge Type',
    BILL_TO_COMPANY: 'Bill to Company',
    INV_CR_NO: 'IN/CN Note No.',
    SENT_DATE_RANGE: 'Sent Date/Time Range',
    STATUS: 'Status',
    CREATED_DATE: 'Created Date',
    CREATED_DATE_RANGE: 'Created Date Range',
    ISSUE_TYPE: 'Issue Type',
    ISSUED_BY: 'Issued By',
    CREATED_BY: 'Created By',

    ATTA_IND: 'Attachment Incl. Ind',
    CONS_CODE: 'Consortium Code',
    VSL_CODE: 'Vessel Code',
    VOY_CODE: 'Voyage Code',
    VSL_NAME: 'Vessel Name',
    SL_VSL_CODE: 'S/L Vsl Code',
    SL_IB_VOY_CODE: 'S/L IB Vsl Code',
    SL_OB_VOY_CODE: 'S/L OB Vsl Code',
    PO_NO: 'P.O. No.',
    SENT_DATE: 'Sent Date/Time',
    RESENT_COUNT: 'Re-send Count',
    ACK_REC_DATE: 'Ack Receipt Date Time',
    LAST_UPDATED_DATE: 'Last Updated Date Time',
}