import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { EdiMonitoringRepoImpl } from "domain/repository/EdiMonitoring/EdiMonitoringRepoImpl";
import { useEdiMonitoringTracked } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import { EdiMonitoringVM } from "presentation/viewModel/EdiMonitoring/EdiMonitoringVM";
import { useMemo } from "react";

export const useEdiMonitoringVM = () => {
    const [, setEdiMonitoringState] = useEdiMonitoringTracked();
    const memoEdiMonitoringVM = useMemo(() =>
        EdiMonitoringVM({
            dispatch: [setEdiMonitoringState],
            chargeTypeRepo: ChargeTypeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            ediMonitoringRepo: EdiMonitoringRepoImpl(),
        }), [setEdiMonitoringState])

    return memoEdiMonitoringVM
}