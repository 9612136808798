import moment from "moment";
import { EMPTY_EDI_MONITORING_SEARCH_CRITERIA } from "presentation/constant/EdiMonitoring/EdiMonitoringSearchCriteria";
import { useEdiMonitoringVM } from "presentation/hook/EdiMonitoring/useEdiMonitoringVM";
import { useEdiMonitoringTracked } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import EdiMonitoringTitleBar from "./EdiMonitoringTitleBar";
import EdiMonitoringSearchPanel from "./LeftPanel/EdiMonitoringSearchPanel";
import EdiMonitoringTablePanel from "./Table/EdiMonitoringTablePanel";


const EdiMonitoringMaintenance:React.FC = () => {
    const [ ediMoniState ] = useEdiMonitoringTracked();
    const { isShowCriteriaPanel } = ediMoniState;
    const ediMoniVM = useEdiMonitoringVM();
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const initialScreen = async() => {   
            try {         
                const results = await Promise.allSettled([
                   ediMoniVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
                        console.log(`Table init successful!`);
                    } 
                })
            } catch(error) {
            }
        }
        initialScreen().then(()=>{
            let newSearchCriteria = { 
                ...EMPTY_EDI_MONITORING_SEARCH_CRITERIA, 
                sentDateFrom: moment().subtract(10, "days").startOf("day").toDate(), 
                sentDateTo: moment().endOf("day").toDate(),
            };
            ediMoniVM.onSearch(newSearchCriteria);
        }).then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
        
    }, [ediMoniVM])

    

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className={`main-comp-wrapper${(isShowCriteriaPanel) ? '' : ' im-hide-side-form-draggable'}`}>
           <EdiMonitoringTitleBar/>

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"22%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"78%":"100%"}
                leftChildren={<EdiMonitoringSearchPanel/>} 
                rightChildren={<EdiMonitoringTablePanel/>}
                />
        </div>
    </>
}

export default memo(EdiMonitoringMaintenance);