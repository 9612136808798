
import { ediMonitoringStatusCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { ediIssueTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { EdiMonitoringConstant } from "presentation/constant/EdiMonitoring/EdiMonitoringConstant";
import { useEdiMonitoringVM } from "presentation/hook/EdiMonitoring/useEdiMonitoringVM";
import { useEdiMonitoringTracked } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { GroupCheckboxList, HPHGroupCheckbox, InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

const EdiMonitoringSearchForm = () => {   
    const [ ediMoniState ] = useEdiMonitoringTracked();
    const ediMoniVM = useEdiMonitoringVM();
    const EDI_MONI_CONST = EdiMonitoringConstant; 
    const { searchCriteria } = ediMoniState;

    
    const memoChgType = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EDI_MONI_CONST.CHARGE_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.chargeTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {
                    ediMoniVM.onMultipleDropdownChange(e, 'chargeTypeList')
                }}
                options={ediMoniState.dynamicOptions.chargeTypeDropdownOptions}/>
        </div>
    , [EDI_MONI_CONST.CHARGE_TYPE, searchCriteria.chargeTypeList, ediMoniState.dynamicOptions.chargeTypeDropdownOptions, ediMoniVM])

    // const memoStatus = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <InputDropdown
    //             label={EDI_MONI_CONST.STATUS}
    //             inputType="freeText"
    //             width="150px"
    //             mode={'multiple'}
    //             sort={false}
    //             value={searchCriteria.statusList?.map((item) => ({
    //                 value: item
    //             }))}
    //             onChange={(e) => ediMoniVM.onMultipleDropdownChange(e, 'statusList')}
    //             options={ediMonitoringStatusDropdownOption}/>
    //     </div>
    // , [EDI_MONI_CONST.STATUS, searchCriteria.statusList, ediMoniVM])

    const memoStatus = useMemo(() =>            
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={EDI_MONI_CONST.STATUS}
                checkboxData={ediMonitoringStatusCheckboxOption}
                selectedValues={searchCriteria.statusList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => ediMoniVM.onGroupCheckboxChange(e, searchCriteria, "statusList")}
            />
        </div>
    , [EDI_MONI_CONST.STATUS, ediMoniVM, searchCriteria])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EDI_MONI_CONST.BILL_TO_COMPANY}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => ediMoniVM.onMultipleDropdownChange(e, 'billToCompList')}
                options={ediMoniState.dynamicOptions.billToCompDropdownOptions}/>
        </div>
    , [EDI_MONI_CONST.BILL_TO_COMPANY, searchCriteria.billToCompList, ediMoniState.dynamicOptions.billToCompDropdownOptions, ediMoniVM])



    const memoSentDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={EDI_MONI_CONST.SENT_DATE_RANGE}
                width='330px'
                fieldNames={{startField:"sentDateFrom", endField:"sentDateTo"}}
                dateRange={{startDate:searchCriteria.sentDateFrom, endDate:searchCriteria.sentDateTo}}
                onDatesChange={ediMoniVM.onDateRangeChange}
            />                
        </div>
    , [EDI_MONI_CONST.SENT_DATE_RANGE, searchCriteria.sentDateFrom, searchCriteria.sentDateTo, ediMoniVM.onDateRangeChange])

    // const memoCreatedDateFromTo = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <DateRangePickerComponent
    //             label={EDI_MONI_CONST.CREATED_DATE_RANGE}
    //             width='330px'
    //             fieldNames={{startField:"createdDateFrom", endField:"createdDateTo"}}
    //             dateRange={{startDate:searchCriteria.createdDateFrom, endDate:searchCriteria.createdDateTo}}
    //             onDatesChange={ediMoniVM.onDateRangeChange}
    //         />                
    //     </div>
    // , [EDI_MONI_CONST.CREATED_DATE_RANGE, searchCriteria.createdDateFrom, searchCriteria.createdDateTo, ediMoniVM.onDateRangeChange])

    const memoInvCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={EDI_MONI_CONST.INV_CR_NO}
                type="text"
                value={searchCriteria.invCreditNoteNo || ''}
                onChange={(e) => ediMoniVM.onInputTextChange(e, 'invCreditNoteNo')}/>
        </div>
    , [EDI_MONI_CONST.INV_CR_NO, searchCriteria.invCreditNoteNo, ediMoniVM])

    const memoIssueType = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EDI_MONI_CONST.ISSUE_TYPE}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.issueTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => ediMoniVM.onMultipleDropdownChange(e, 'issueTypeList')}
                options={ediIssueTypeDropdownOption}/>
        </div>
    , [EDI_MONI_CONST.ISSUE_TYPE, searchCriteria.issueTypeList, ediMoniVM])

    const memoIssueBy = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EDI_MONI_CONST.ISSUED_BY}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.issuedByList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => ediMoniVM.onMultipleDropdownChange(e, 'issuedByList')}
                options={ediMoniState.dynamicOptions.issuedByDropdownOptions}/>
        </div>
    , [EDI_MONI_CONST.ISSUED_BY, searchCriteria.issuedByList, ediMoniState.dynamicOptions.issuedByDropdownOptions, ediMoniVM])

    const memoCreatedBy = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EDI_MONI_CONST.CREATED_BY}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.createdByList?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => ediMoniVM.onMultipleDropdownChange(e, 'createdByList')}
                options={ediMoniState.dynamicOptions.createdByDropdownOptions}/>
        </div>
    , [EDI_MONI_CONST.CREATED_BY, searchCriteria.createdByList, ediMoniState.dynamicOptions.createdByDropdownOptions, ediMoniVM])

    return <>              

        <CriteriaItemContainer>
            {memoStatus}
        </CriteriaItemContainer>  
        
        <CriteriaItemContainer>
           {memoChgType}
           {memoBillToCompany}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
           {memoInvCreditNoteNo}
           {memoIssueType}  
        </CriteriaItemContainer>

        <CriteriaItemContainer>
        {memoIssueBy}   
        {memoCreatedBy}                
        </CriteriaItemContainer>

        
        <CriteriaItemContainer>
           {memoSentDateFromTo}
        </CriteriaItemContainer>
        
    </>;
}
export default memo(EdiMonitoringSearchForm);