import _ from "lodash";
import { EdiMonitoringConstant } from "presentation/constant/EdiMonitoring/EdiMonitoringConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useEdiMonitoringVM } from "presentation/hook/EdiMonitoring/useEdiMonitoringVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useEdiMonitoringTracked } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const EdiMonitoringTitleBar:React.FC = () => {
    const [ ediMoniState ] = useEdiMonitoringTracked();
    const ediMoniVM = useEdiMonitoringVM();
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState(false);
    const selectedRows = ediMoniState.selectedEdiMonitoringRows;
    const [anaInfoState] = useANAInfoTracked();
    const {allowDelete} = anaInfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    
    const handleSearchClick = useCallback(() => {
        ediMoniVM.onToggleCriteriaPanel();
    }, [ediMoniVM]);

    const handleDelete = async () => {
        if(_.isEmpty(selectedRows)){
            messageBarVM.showWarining('Please select a record.');
            return;
        }
        setIsShowDelete(false);
        setIsLoading(true);
        try {
            await ediMoniVM.onDelete(selectedRows);   
            await ediMoniVM.onSearch(ediMoniState.searchCriteria); 
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
        
    }
    
    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const isDisable = () => {
        if (selectedRows && selectedRows.length > 0) {
            return false;
        }
        return true;
    }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{EdiMonitoringConstant.MONITOR}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper"> 
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
            {(allowDelete) && <><div className="add-seperator"/>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_DELETE} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleDeleteClick} /></>}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${EdiMonitoringConstant.MONITOR}`} 
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
    </Sidebarheader>
}

export default memo(EdiMonitoringTitleBar);