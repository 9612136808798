export interface EdiMonitoringSearchCriteria {
    chargeTypeList?: string[] | null,
    billToCompList?: string[] | null,
    invCreditNoteNo?: string | null,
    sentDateFrom?: Date | null,
    sentDateTo?: Date | null,
    statusList?: string[] | null,
    issueTypeList?: string[] | null,
    issuedByList?: string[] | null,
    createdByList?: string[] | null,
    createdDateFrom?: Date | null,
    createdDateTo?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_EDI_MONITORING_SEARCH_CRITERIA : EdiMonitoringSearchCriteria = {
    chargeTypeList: [],
    billToCompList: [],
    invCreditNoteNo: null,
    sentDateFrom: null,
    sentDateTo: null,
    statusList: [],
    issueTypeList: [],
    issuedByList: [],
    createdByList: [],
    createdDateFrom: null,
    createdDateTo: null,

}