
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EdiMonitoringEntity } from "domain/entity/EdiMonitoring/EdiMonitoringEntity";
import _ from "lodash";
import { EdiMonitoringSearchCriteria } from "presentation/constant/EdiMonitoring/EdiMonitoringSearchCriteria";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import documentAxiosInstance from "../axios/documentAxiosInstance";
import { EdiMonitoringRepository } from "./EdiMonitoringRepo";

export const EdiMonitoringRepoImpl = () : EdiMonitoringRepository => {
    const searchUrl = "/v1/ediMonitoringSearch"
    const deleteUrl = "/v1/ediMonitoringDelete"
    const getIssuedByUrl = "/v1/ediMonitoringIssuedBy"
    const getCreatedByUrl = "/v1/ediMonitoringCreatedBy"

    /**
     * Get EDI monitoring data by criteria
     * 
     * @param request 
     * @returns
     */
    const getMonitoringData = (request: EdiMonitoringSearchCriteria) : Promise<EdiMonitoringEntity[]> => {
        return axiosPostData(documentAxiosInstance, `${searchUrl}`, request).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    /**
     * Delete the monitoring data by id
     * 
     * @param ids 
     * @returns 
     */
    const deleteMonitoringData = (ids: number[]) : Promise<ResponseEntity> => {
        return axiosPostData(documentAxiosInstance, `${deleteUrl}`, ids)
    }

    /**
     * Get the combox values for 'Issued By'
     * @returns 
     */
    const getEdiMonitoringIssuedBy = async() : Promise<string[]> => {
        return axiosGetData(documentAxiosInstance, getIssuedByUrl, []).then(res => {
            const data = res.data;
            return _.sortBy(data);
        }).catch(err => {
            return [];
        })
    }

    /**
     * Get the combox values for 'Created By'
     * @returns 
     */
    const getEdiMonitoringCreatedBy = async() : Promise<string[]> => {
        return axiosGetData(documentAxiosInstance, getCreatedByUrl, []).then(res => {
            const data = res.data;
            return _.sortBy(data);
        }).catch(err => {
            return [];
        })
    }

    return {
        getMonitoringData: getMonitoringData,
        deleteMonitoringData: deleteMonitoringData,
        getEdiMonitoringIssuedBy: getEdiMonitoringIssuedBy,
        getEdiMonitoringCreatedBy: getEdiMonitoringCreatedBy,
    }
}