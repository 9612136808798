import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { EdiMonitoringProvider } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import EdiMonitoringMaintenance from "presentation/view/section/EdiMonitoring/EdiMonitoringMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const EdiMonitoringContMain = () => {
    return <ANAInfoWrapper permission={Permission.DOCUMENT_EDI_MONITORING}>
        <MessageBarWrapper>
            <EdiMonitoringProvider>
                <GridStyles/>
                <EdiMonitoringMaintenance/>
            </EdiMonitoringProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default EdiMonitoringContMain;