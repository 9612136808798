import { EdiMonitoringSearchCriteria } from "presentation/constant/EdiMonitoring/EdiMonitoringSearchCriteria";
import { useEdiMonitoringVM } from "presentation/hook/EdiMonitoring/useEdiMonitoringVM";
import { useEdiMonitoringTracked } from "presentation/store/EdiMonitoring/EdiMonitoringProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import EdiMonitoringSearchForm from "./EdiMonitoringSearchForm";

const EdiMonitoringSearchPanel = () => {
    const ediMoniVM = useEdiMonitoringVM();
    const [ediMoniState] = useEdiMonitoringTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        ediMoniVM.onRemoveAllSearchCriteria();
    }, [ediMoniVM]);
    
    const searchButtonClicked = useCallback((criteria:EdiMonitoringSearchCriteria) => {
        setIsLoading(true);
        ediMoniVM.onSearch(criteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [ediMoniVM])


    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Search Criteria'}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={ediMoniVM.onToggleCriteriaPanel} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <EdiMonitoringSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(ediMoniState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default EdiMonitoringSearchPanel;
