import { EdiMonitoringEntity } from "domain/entity/EdiMonitoring/EdiMonitoringEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { EdiMonitoringRepository } from "domain/repository/EdiMonitoring/EdiMonitoringRepo";
import _ from "lodash";
import { EdiMonitoringSearchCriteria, EMPTY_EDI_MONITORING_SEARCH_CRITERIA } from "presentation/constant/EdiMonitoring/EdiMonitoringSearchCriteria";
import { DropdownProps } from "presentation/model/DropdownProps";
import { EdiMonitoringModel } from "presentation/model/EdiMonitoring/EdiMonitoringModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";

interface EdiMonitoringVMVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<EdiMonitoringModel>> | ((value: SetStateAction<EdiMonitoringModel>) => void),
    ],
    chargeTypeRepo: ChargeTypeRepository,
    companyRepo: CompanyRepository,
    ediMonitoringRepo: EdiMonitoringRepository,
}

export const EdiMonitoringVM = ({ dispatch, chargeTypeRepo,  companyRepo,ediMonitoringRepo}: EdiMonitoringVMVMProps) => {
    const [ediMoniDispatch] = dispatch;
    
    const updateSelectedEdiMonitorings = (rows:any[]) => {
        ediMoniDispatch(prevState => {
            return {
                ...prevState,
                selectedEdiMonitoringRows: rows                       
            }
        })
    }
    
    const onSearch = async (searchCriteria: EdiMonitoringSearchCriteria) => {
        // DomUtils.addWithSearchClass();
        ediMoniDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {...searchCriteria},
                ediMonitorings: [],
                selectedCreditNoteHeaderRows: [],
            }
        })
        // let newStatusList: string[] = [];  
        // if (searchCriteria.statusList) {  
        //     newStatusList = searchCriteria.statusList.map(  
        //         status => Object.keys(DocumentConst.ediMonitoringStatus).find(  
        //             key => DocumentConst.ediMonitoringStatus[key as keyof typeof DocumentConst.ediMonitoringStatus] === status  
        //         ) || ''  
        //     ).filter(result => typeof result === 'string'); 
        // }
        await ediMonitoringRepo.getMonitoringData({
            ...searchCriteria,
            // statusList: newStatusList
        }).then((data) => {
            ediMoniDispatch(prevState => {
                return {
                    ...prevState,
                    ediMonitorings: data,
                    selectedEdiMonitoringRows: [],
                }
            })
        })
    }

    const loadDropdownOption = async() => {
        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions:DropdownProps[] = [];
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                });
                
                ediMoniDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                    },
                    
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                ediMoniDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        )

        await ediMonitoringRepo.getEdiMonitoringIssuedBy().then(
            issuedByList => {
                let issuedByDropdownOptions = issuedByList?.map((issuedBy) => ({
                    dropdownLabel: issuedBy,
                    tagLabel: issuedBy,
                    value: issuedBy,
                })) ?? []

                ediMoniDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        issuedByDropdownOptions: issuedByDropdownOptions,
                    }
                }))
            }
        )

        await ediMonitoringRepo.getEdiMonitoringCreatedBy().then(
            createdByList => {
                let createdByDropdownOptions = createdByList?.map((createdBy) => ({
                    dropdownLabel: createdBy,
                    tagLabel: createdBy,
                    value: createdBy,
                })) ?? []

                ediMoniDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        createdByDropdownOptions: createdByDropdownOptions,
                    }
                }))
            }
        )
    }

    
    const onDelete = async (selectedRows: EdiMonitoringEntity[]) => {
        
        return await ediMonitoringRepo.deleteMonitoringData(
            selectedRows.map(row => row.id) as number[]
        )
    }

    const onRemoveAllSearchCriteria = () => {
        ediMoniDispatch(prevState => {            
            return {
                ...prevState, searchCriteria: { ...EMPTY_EDI_MONITORING_SEARCH_CRITERIA }
            }
        })
    }

    const onToggleCriteriaPanel = async() => {
        ediMoniDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }
    
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        ediMoniDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        ediMoniDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    
    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        ediMoniDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value.toUpperCase().replace(/\s+/g, ''),
            }
        }))
    };

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria:EdiMonitoringSearchCriteria, fieldName:string) => {
        let selectedValue:string[] = [];
            if (e) {
                e.forEach(function(value, index) {
                    if (value) {
                        selectedValue.push(value.key);
                    }
                });
            }
        searchCriteria = {...searchCriteria, [fieldName]:selectedValue}
        ediMoniDispatch(prevState => ({
            ...prevState,
            searchCriteria : {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };
  
    return {
        updateSelectedEdiMonitorings: updateSelectedEdiMonitorings,
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onDelete: onDelete,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onToggleCriteriaPanel: onToggleCriteriaPanel,
        onDateRangeChange: onDateRangeChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onInputTextChange: onInputTextChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
    }
}